/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { BrowserRouter, NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getBot } from '../../tabs/home/api/bots'
import { HOME_PATH } from '../../configs'
import {hasCustomDocumentation, hasPermissionForBot} from '../../security'
import { mainRoutes } from '../../routes/mainRoutes'
import PermissionDenied from '../404/404'
import Loader from '../../uiKit/loaders/loader'
import WebSockets from '../../contexts/webSockets'
import { clearOldBot } from '../../tabs/home/actions/activeBot'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { UserMenu } from '../../tabs/account/components/UserMenu'
import { clearAttributes } from '../../tabs/settings/actions/attributes'
import { clearWidgetSettings } from '../../tabs/widget/actions/botWidget'
import { ArrowLeft } from '../../uiKit/icons/ArrowLeft'
import { initCopilotWidgetScript, removeCopilotWidget } from './copilot/api/copilot'

import { styles } from './styles'
import { isNotError } from '../../tabs/authorization/actions/forbiddenError'
// eslint-disable-next-line max-len
import { FacebookActivePagesProvider } from '../../tabs/settings/components/integrations/facebook/FacebookActivePages/context'
import { InfoIcon } from '../../uiKit/icons/Info'
import TestButtons from './TestButtons/TestButtons'

class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      redirectToBots: false,
      activeBot: null,
      ifCreateSocket: false,
      redirectToAccountSettings: false,
    }
  }

  componentDidMount() {
    getBot(this.props.match.params.botId)
  }

  componentDidUpdate() {
    this.addCopilotWidget()
    if (this.props.activeBot) {
      localStorage.setItem('activeBotName', this.props.activeBot.name)
    }
  }

  componentWillUnmount() {
    clearOldBot()
    clearAttributes()
    clearWidgetSettings()

    this.setState({ copilotWidgetInitialized: false })
    removeCopilotWidget()
  }

  addCopilotWidget() {
    const shouldAddCopilot =
      !this.state.copilotWidgetInitialized &&
      this.props.adminUser &&
      this.props.activeBot &&
      hasPermissionForBot(this.props.activeBot.id, 'copilot')
    if (shouldAddCopilot) {
      initCopilotWidgetScript(this.props.adminUser, this.props.activeBot)
      this.setState({ copilotWidgetInitialized: true })
    }
  }

  redirectToBots() {
    this.setState({ redirectToBots: true }, () => isNotError())
  }

  redirectToAccountSettings = () => {
    this.setState({ redirectToAccountSettings: true })
  }
  handleOpenDocumentation() {
    let link
    if (hasCustomDocumentation()) {
      link = 'https://enterpriseplatform.notion.site/Help-Support-8228d8541d4e4798b487e632bd27b3a5'
    } else {
      link = 'https://enterpriseplatform.notion.site/Help-Support-bb8d5a0a4661423f9cedbd208a18af43'
    }

    window.open(link, '_blank')
  }

  unassignedToAndUnreadCounter(supportRequests, styleClasses) {
    return supportRequests.unassignedCount || supportRequests.unreadAssignedToCount ? (
      <div className={styleClasses}>{supportRequests.unassignedCount + supportRequests.unreadAssignedToCount}</div>
    ) : null
  }

  render() {
    if (this.state.redirectToBots) {
      return <Redirect to={HOME_PATH} />
    }

    if (this.state.redirectToAccountSettings) {
      return (
        <Redirect
          to={{
            pathname: '/account-settings',
            state: { from: this.props.location.pathname },
          }}
        />
      )
    }

    const { classes, activeBot, supportRequests } = this.props
    const pathLink = `${HOME_PATH}/bot/${this.props.match.params.botId}/`
    const pathRoute = `${HOME_PATH}/bot/:botId/`
    const isFunnel = window.location.href.match(/analytics\/\d/g)

    return (
      <div className={classes.root}>
        {this.props.adminUser ? (
          <BrowserRouter>
            <WebSockets
              adminUser={this.props.adminUser}
              botId={this.props.match.params.botId}
              publicBotId={activeBot?.publicIdentifier}>
              <FacebookActivePagesProvider>
                <div className={classes.appFrame}>
                  <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar className={classes.Toolbar} disableGutters={!this.state.open}>
                      {isFunnel ? (
                        <NavLink to={`${HOME_PATH}/bot/${this.props.match.params.botId}/analytics`}>
                          <p className={classes.backToAnalytics}>
                            <ArrowLeft /> Back to Analytics
                          </p>
                        </NavLink>
                      ) : (
                        <p onClick={() => this.redirectToBots()} className={classes.homeButton}>
                          Home
                        </p>
                      )}
                      {this.props.activeBot && (
                        <Typography type="title" style={{ fontFamily: 'Lato, sans-serif' }} className={classes.title}>
                          {this.props.activeBot.name}
                        </Typography>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TestButtons classes={classes} />
                        <UserMenu
                          redirectToAccountSettings={this.redirectToAccountSettings}
                          botId={this.props.match.params.botId}
                        />
                      </div>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.menuWrap}>
                    <List className={classes.list}>
                      {mainRoutes.map(
                        route =>
                          hasPermissionForBot(this.props.match.params.botId, route.path) && (
                            <NavLink
                              key={route.path}
                              className={classes.link}
                              activeClassName={classes.activeLink}
                              to={`${pathLink}${route.path}`}>
                              {route.path === 'support' &&
                                this.unassignedToAndUnreadCounter(supportRequests, classes.supportAmount)}
                              {route.icon}
                              <p className={classes.listItem}>{route.name}</p>
                            </NavLink>
                          ),
                      )}
                    </List>
                    <div className={classes.docLink} onClick={this.handleOpenDocumentation}>
                      <InfoIcon color={'var(--color-text-on-primary)'} />
                      <span>Help</span>
                    </div>
                  </div>
                  <Divider />
                  <main className={classes.content}>
                    <ErrorBoundary>
                      <Switch>
                        {mainRoutes.map(
                          ({ path, component: Component }) =>
                            hasPermissionForBot(this.props.match.params.botId, path) && (
                              <Route path={`${pathRoute}${path}`} key={path} render={rest => <Component {...rest} />} />
                            ),
                        )}
                        <Route component={() => <PermissionDenied botId={this.props.match.params.botId} />} />
                      </Switch>
                    </ErrorBoundary>
                  </main>
                </div>
              </FacebookActivePagesProvider>
            </WebSockets>
          </BrowserRouter>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  supportRequests: state.supportRequests,
  adminUser: state.adminUser,
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(MainMenu)))
