/**
 * Created by Admin on 08.05.2018.
 */

import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from './api'
import { saveAdminUser } from './tabs/authorization/actions/adminUser'
import { BASE_URL } from './configs'
import { nlpRoutes } from 'routes/nlpRoutes'
import * as FullStory from '@fullstory/browser'

export const ROLE = {
  ADMIN: 'ROLE_ADMIN',
  SIMPLE_ADMIN: 'ROLE_SIMPLE_ADMIN',
  SUPPORT: 'ROLE_SUPPORT',
  MANAGER: 'ROLE_MANAGER',
}

const getSimpleAdminAllowedNlpRoutes = () => {
  return nlpRoutes.filter(route => route.name !== 'Intents' && route.name !== 'Triggers')
}

export const ROLE_OPTIONS = [
  {
    value: ROLE.ADMIN,
    label: 'Admin',
    features: [
      'support',
      'dashboard',
      'flows',
      'atomBuilder',
      'settings',
      'nlp',
      'broadcast',
      'widget',
      'createBot',
      'shareBot',
      'analytics',
      'copilot',
    ],
    nlpRoutes: nlpRoutes,
  },
  {
    value: ROLE.SIMPLE_ADMIN,
    label: 'Simple admin',
    features: [
      'support',
      'dashboard',
      'flows',
      'atomBuilder',
      'settings',
      'nlp',
      'widget',
      'createBot',
      'shareBot',
      'analytics',
      'copilot',
    ],
    nlpRoutes: getSimpleAdminAllowedNlpRoutes(),
  },
  {
    value: ROLE.SUPPORT,
    label: 'Support',
    features: ['support', 'dashboard', 'analytics'],
  },
  {
    value: ROLE.MANAGER,
    label: 'Manager',
    features: [
      'support',
      'dashboard',
      'flows',
      'atomBuilder',
      'nlp',
      'widget',
      'analytics',
      'copilot',
      'hideNlpSettings'
    ],
    nlpRoutes: nlpRoutes,
  },
]

export const access = {
  userRole: null,
  botAccesses: [],

  loadRole() {
    fetch(BASE_URL + '/getAdminUser', {
      credentials: 'include',
      method: 'GET',
      headers: createJsonHeaders(),
    })
      .then(response => logoutOnRequestOrResponseJson(response))
      .then(json => {
        access.userRole = json.role
        access.botAccesses = json.botAccesses
        saveAdminUser(json)
        if (process.env.REACT_APP_FULL_STORY_ORG_ID) {
          FullStory.identify(json.id, { email: json.email })
        }
        return json
      })
      .catch(function(error) {
        alertError('Sorry but something is going wrong please ping support!')
      })
  },
}

export function hasPermissionForBot(botId, featureName) {
  const botAccess = access?.botAccesses?.filter(
    ba => ba.botId === Number(botId),
  )[0]

  return (
    botAccess &&
    ROLE_OPTIONS?.find(
      role => role.value === botAccess.role,
    )?.features?.includes(featureName)
  )
}

export function hasCustomDocumentation() {
  return (
    access?.botAccesses[0]?.role === ROLE.MANAGER ||
    access?.botAccesses[0]?.role === ROLE.SUPPORT
  )
}

export function hasPermissionForFeature(featureName) {
  const userRole = access?.botAccesses[0]?.role

  return ROLE_OPTIONS?.find(
    role => role.value === userRole,
  )?.features?.includes(featureName)
}
